:root {
    --laptopscreen: 50%;
    --mobilescreen: 12%;
}

#auth-container {
    margin-top: 15px;
}

#auth-container h1 {
    font-weight: bold;
    margin: 0;
}

#auth-container h2 {
    text-align: center;
}

#auth-container p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

#auth-container span {
    font-size: 12px;
}

#auth-container a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

#auth-container button {
    border-radius: 20px;
    border: 1px solid #21213d;
    background-color: #21213d;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 5px;
    transition: transform 80ms ease-in;
}

#auth-container button:active {
    transform: scale(0.95);
}

#auth-container button:focus {
    outline: none;
}

#auth-container button.ghost {
    background-color: transparent;
    border-color: #ffffff;
}

#auth-container form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

#auth-container input {
    background-color: #eee;
    border: none;
    width: 100%;
    margin: 8px 0;
    padding: 12px 15px;
}

#auth-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

#login-card {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: #fff;

    width: 768px;
    max-width: 100%;
    min-height: 600px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.login-card.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.login-card.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login-card.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    background: linear-gradient(90deg, rgb(34, 41, 87) 0%, #325581 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login-card.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.login-card.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.login-card.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

#auth-container footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

#auth-container footer p {
    margin: 10px 0;
}

#auth-container footer i {
    color: red;
}

#auth-container footer a {
    color: #3c97bf;
    text-decoration: none;
}

#auth-container .resend-otp {
    font-size: 12px;
    color: #525265;
    font-weight: 800;
}

.extra-visible-small {
    visibility: hidden;
    display: none;
}

.terms-privacy{
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 768px) {
    .overlay-container {
        display: none;
        visibility: hidden;
    }

    #login-card {
        box-shadow: none;
    }

    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }

    .login-card.right-panel-active .sign-up-container {
        transform: translateX(var(--laptopscreen));
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .login-card .sign-in-container {
        transform: translateX(var(--laptopscreen));
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .login-card.right-panel-active .sign-in-container {
        transform: translateX(var(--laptopscreen));
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .extra-visible-small {
        visibility: visible;
        display: block;
    }

    #auth-container a {
       margin: 0;
    }

    #auth-extra-top-margin a{
        margin-top: 15px;
    }
}

@media (max-width: 600px) {
    .overlay-container {
        display: none;
        visibility: hidden;
    }

    #login-card {
        box-shadow: none;
    }

    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
        width: 80%;
    }

    .login-card.right-panel-active .sign-up-container {
        transform: translateX(var(--mobilescreen));
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .login-card .sign-in-container {
        transform: translateX(var(--mobilescreen));
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .login-card.right-panel-active .sign-in-container {
        transform: translateX(var(--mobilescreen));
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .extra-visible-small {
        visibility: visible;
        display: block;
    }

    #auth-container a {
        margin: 0;
    }

    #auth-extra-top-margin a{
        margin-top: 15px;
    }
}