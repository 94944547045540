@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.confirmation-component-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-family: "Roboto Condensed";
}

.confirmation-component {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eeeded;
    border-radius: 15px;
    padding: 20px 25px;
    z-index: 200;
}

.confirmation-screen-cancel-button, 
.confirmation-screen-success-button{
    margin: 2px 10px;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    padding: 8px 20px;
    font-weight: 600;
    font-size: 16px;
}

.confirmation-screen-success-button{
    background-color: #6c1f1f;
}

.confirmation-screen-cancel-button{
    background-color: #74747c;
}

.confirmation-screen-success-button:hover{
    background-color: #571919;
}

.confirmation-screen-cancel-button:hover{
    background-color: #575759;
}

@media (max-width: 600px) {
    .confirmation-component-buttons{
        display: flex;
        flex-direction: column-reverse;
    }
}