@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

:root {
    --titlepad: 8px;
}

.HeaderComponetDiv {
    height: 65px;
    border-bottom: 3px;
    font-family: "Roboto Condensed";
    position: absolute;
    z-index: 1000;
    width: 100%;
}

.logo {
    margin-left: 40px;
    display: flex;
    flex-direction: row;
}

a.logo {
    text-decoration: none;
    color: #ffffff;
    font-size: 30px;
}

a.extension {
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 7px 7px;
    margin: 12px;
    position: relative;
}

a.extension:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

a.extension:hover:after {
    width: 100%;
    left: 0;
}

.logoImg {
    margin: 10px;
    width: 39px;
    border-radius: 5px;
}

.signin {
    color: #ffffff;
    cursor: pointer;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 7px 7px;
    margin: 12px;
    position: relative;
}

.signin:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.signin:hover:after {
    width: 100%;
    left: 0;
}

.signup {
    color: #ff3366;
    cursor: pointer;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 7px 7px;
    margin: 12px;
    position: relative;
}

.signup:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #d20438;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.signup:hover:after {
    width: 100%;
    left: 0;
}

.signup:hover {
    color: #d20438;
}

.header-menu {
    width: 100%;
    justify-content: right;
    text-align: right;
}

.header-company-name {
    margin-top: 10px;
}

@media (max-width: 540px) {
    a.extension {
        margin: 0px;
        padding-right: var(--titlepad);
        padding-left: 0px;
    }

    .signin {
        margin: 0px;
        padding-right: var(--titlepad);
        padding-left: var(--titlepad);
    }

    .signup {
        margin: 0px;
        padding-right: var(--titlepad);
        padding-left: var(--titlepad);
        margin-right: 15px;
    }

    .header-company-name {
        width: 0px;
        visibility: hidden;
        display: none;
    }
}
