@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap");

.RecordShareComponentDiv {
    font-family: "Roboto Condensed";
    width: 100%;
    min-height: 100vh;
    background: #d9ecf147;
    color: rgb(48, 48, 48);
}

.record-share-header-section {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.record-share-left {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
}

.record-share-right {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.record-share-button {
    padding: 5px 20px;
    margin-right: 20px;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: aliceblue;
    background-color: #023e8ae6;
}

.dropdown-shared {
    margin: 62px 36px 0px 0px;
}

.record-share-button:hover {
    background-color: #013271e6;
}

.record-share-body-section {
    text-align: center;
    margin-top: 50px;
}

.record-share-record-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #464747;
}

.record-share-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 40px;
}

.record-share-play-pause-button {
    font-size: 50px;
    background: var(--primary);
    border: none;
    color: #053a7b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
}

.record-share-datetime {
    text-align: left;
    margin-left: 16%;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 600;
}

.record-share-shared-dropdown {
    text-align: left;
    margin-left: 16%;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
}

.record-share-copy-link {
    cursor: pointer;
}

.record-share-copy-link:after {
    content: "\e157";
    font-family: "Material Icons";
    font-size: 28px;
    margin-right: 20px;
    margin: 0px 20px 0px 0px;
    color: #052b46;
    cursor: pointer;
}

.record-share-list-button {
    padding: 5px 20px;
    margin-right: 20px;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: aliceblue;
    background-color: #558391;
}

.record-share-list-button:hover {
    background-color: #466873;
}

.copy-link-tooltip {
    position: absolute;
    color: #5b5a5a;
    z-index: 1001;
    margin-top: 20px;
    margin-left: -35px;
    overflow: hidden;
}

.copy-link-tooltip-text {
    padding: 8px 15px;
    border: 0px solid #ddd;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #000000cc;
    color: white;
}

.record-share-main-buttons{
    display: flex;
    flex-direction: row;
}

.create-share{
    min-width: 400px;
}

.echovault-ext-toggle-button {
    text-transform: none;
    border: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    font-weight: 500;
    line-height: 0;
    position: relative;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    color:#3e424a;
}

.echovault-ext-toggle-button:hover {
    background-color: transparent;
    color: rgb(43, 42, 42);
}

@media (max-width: 1200px) {
    .record-share-controls {
        margin-left: 2px;
    }

    .record-share-datetime {
        margin-left: 17%;
    }

    .record-share-shared-dropdown {
        margin-left: 17%;
    }
}

@media (max-width: 800px) {
    .record-share-controls {
        margin-left: 2px;
    }

    .record-share-datetime {
        margin-left: 22%;
    }

    .record-share-shared-dropdown {
        margin-left: 22%;
    }
}

.share-record-clips-button{
    margin-left: 0px;
}