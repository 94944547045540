@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.PricingPlansComponent {
    font-family: "Roboto Condensed";
    background-color: #f7f7ff;
    height: 100%;
}

.pricing-heading-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: fit-content;
    padding: 4rem;
}

.pricing-heading-title {
    font-size: 1.875rem;
    font-weight: 800;
    color: #000000;
    line-height: 1.4;
    margin: none 0px;
}

.pricing-heading-description {
    margin-top: 1.25rem;
    max-width: 48rem;
    font-size: 1.125rem;
    color: #66757f;
}

.pricing-plans-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.pricing-plan-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e7eb;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 1rem;
    margin: 10px 20px;
    width: 420px;
}

.pricing-plan-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
}

.pricing-plan-description {
    margin-top: 1rem;
    color: #66757f;
    line-height: 0.7;
}

.pricing-plan-price-container {
    margin-top: 1rem;
    background-color: #f4f4f5;
    padding: 20px;
    border-radius: 0.75rem;
}

.pricing-plan-price {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: #66757f;
}

.pricing-plan-price span:last-child {
    margin-left: 0.375rem;
}

.pricing-price-amount {
    font-size: 24px;
    color: #141414e2;
    font-weight: 700;
}

.pricing-plan-features {
    margin-top: 1rem;
    flex: 1;
}

.pricing-plan-feature {
    display: flex;
    font-size: 1rem;
    line-height: 3;
}

.pricing-plan-feature:hover {
    cursor: default;
    background-color: white;
}

.pricing-plan-feature svg {
    height: 1.25rem;
    width: 1.25rem;
    color: #22d3ee;
    margin-right: 0.75rem;
}

.pricing-plan-button {
    text-decoration: none;
    margin-top: 1.5rem;
    background-color: #93c5fd;
    color: #1e598a;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-plan-button:hover {
    background-color: #6cb6ff;
    cursor: pointer;
}

.pricing-tick-li {
    margin: auto 0;
}

.grayed-text{
    color: #394a58;
    background-color: #c9e2fc;
}

.pricing-plan-button:hover {
    background-color: #c9e2fc;
}

.loading-icon-subscription:after {
    content: "\f053";
    font-family: "Material Icons";
}
@media (max-width: 640px) {
    .pricing-heading-container {
        padding: 2rem;
    }

    .pricing-plans-container {
        display: flex;
        flex-wrap: wrap;
    }

    .pricing-plan-card {
        width: calc(100% - 2rem);
        margin: 1rem auto;
    }

    .pricing-heading-title {
        font-size: 1.4rem;
    }
}
