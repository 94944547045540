@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.sidebar-wrapper {
    font-family: "Roboto Condensed";
    margin-left: 27px;
    color: #00132a;
}

.sidebar-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.logo-text {
    font-size: 20px;
    color: #fff;
}

.sidebar-site-name {
    position: absolute;
    top: 35px;
    font-weight: 900;
    left: 90px;
    font-size: 23px;
    transition: opacity 0.3s, transform 0.3s;
    color: #172d42;
}

.sidebar-text-links {
    margin-top: 50px;
}

.sidebar-text-link {
    text-decoration: none;
    display: block;
    padding: 10px 10px;
    transition: color 0.3s, background-color 0.3s;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    margin: 1px;
    cursor: pointer;
    color: #002756;
    width: 260px;
    margin-top: 12px;
}

.sidebar-text-link:hover {
    color: #325581;
    background-color: #ccd4e2;
}

.sidebar-item-selected{
    color: #325581;
    background-color: #ccd4e2;
}

.sidebar-item-name {
    margin-left: 18px;
    font-size: 17px;
}

.sidebar-icon{
    font-size: 20px;
}

.copyright-notice{
    position: absolute;
    bottom: 18px;
    text-align: center;
    margin-left: 76px;
}

.sidebar-horizontal-row{
    border-top: 2px solid #dededea8;
    width: 94%;
    position: absolute;
    bottom: 72px;
    left: 7px;
}

.sidebar-close-icon{
    position: absolute;
    top: 37px;
    right: 10px;
    font-size: 26px;
    cursor: pointer;
    transition: opacity 0.3s, transform 0.3s;
    color: #252525;   
}

.sidebar-signout{
    position: absolute;
    position: absolute;
    bottom: 10px;
    left: 7px;
    display: flex;
    flex-direction: row;
    vertical-align:middle;
}

.sidebar-signout-texts{
    display: flex;
    flex-direction: column;
    width: 166px;
}

.signoutname{
    padding: 0px;
    margin-left: 20px;
    margin-top: 0px;
    width: auto;
}

.signoutname:hover{
    background-color: #e6eaf1;
}

.sign-out-icon{
    width: auto;
    margin-top: 15px;
}

.logo-circle{
    margin-right: 0px;
}