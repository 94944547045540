.progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.diplay-recorded-canvas {
    width: 75%;
    height: 200px;
    background: #232323c7;
    position: relative;
}

.bar-controller {
    position: absolute;
    top: 50%;
    height: 100%;
    width: 1px;
    background-color: white;
    transform: translateY(-50%);
}

.decoration-front,
.decoration-rear {
    position: absolute;
    height: 100%;
    width: 10px;
    background-color: #1a3b5a;
}

.decoration-front {
    left: 0;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
}

.decoration-rear {
    right: 0;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
}
