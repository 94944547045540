.empty-component {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.436);
}
