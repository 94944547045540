@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.form-container-help{
    font-family: "Roboto Condensed";
    margin-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.help-form-title {
    font-size: 30px;
    font-weight: 700;
    color: #1f334ec4;
}

.help-form-subtitle {
    font-size: 18px;
    font-weight: 350;
}

.help-form-field{
    width: 600px;
    margin: 0px auto;
    margin-top: 40px;
    height: 50px;
    text-align: center;
    border-radius: 10px;
    border: solid;
    border-width: 2px;
}

.help-submit-button{
    width: 130px;
    margin-bottom: 80px;
    font-weight: 700;
    font-size: 20px;
    background-color: #023271e6;
    color: white;
    border: none;
}

@media (max-width: 768px) {
    .help-form-field{
        width: 350px;
    }

    .help-submit-button{
        width: 130px;
    }
}
