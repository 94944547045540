@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@600&family=Inter:wght@900&family=Work+Sans:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Work+Sans:wght@100;200;300;400&display=swap");

.ProfileComponentDiv {
    margin: 10px;
    font-family: "Roboto Condensed";
}

.person-name {
    font-weight: 900;
    font-size: 14px;
    color: #7b7985;
}

.profile-fields {
    display: flex;
    flex-direction: column;
}

.personal-settings {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 900;
}

.edit-info-title,
.contact-section-title,
.delete-section-title {
    font-weight: 510;
    font-size: 18px;
}

#user-plane-title{
    font-weight: 510;
    font-size: 16px;
    padding-top: 15px;
}

#next-payment-date{
    color: #636363;
}

.cancel-subscription-button{
    color: #6c1f1f;
    background-color: #ffffff;
    border-radius: 10px;
    border-width: 1px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 500;
}

.cancel-subscription-button:hover{
    background-color: #f7f7fc;
}

.customer-portal-button {
    color: rgb(88, 83, 83);
    margin-left: 5px;
}

.field-name input,
.profile-fields input {
    border-radius: 20px;
    padding: 2px 10px;
    border: solid;
    border-width: 2px;
    max-width: 300px;
    margin-top: 10px;
}

.field-name input:hover,
.field-name input:focus,
.profile-fields input:hover,
.profile-fields input:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(43, 45, 124, 0.2);
}

.field-name {
    display: flex;
    flex-direction: column;
}

.info-titles {
    padding-top: 10px;
    padding-left: 10px;
}

.logo-redirect {
    text-align: right;
    cursor: pointer;
}

.personal-setting-title {
    margin-right: 10px;
}

.profile-fields input {
    padding: 8px 15px;
    width: 100%;
}

.profile-edit-button {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.delete-button,
.edit-profile-button,
.save-button,
.cancel-button,
.subscribe-button {
    background-color: #023e8ae6;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px 20px;
}

.cancel-button {
    background-color: #012b61e6;
}

.delete-button {
    margin-top: 20px;
    background-color: #6c1f1f;
}

.subscribe-button {
    padding: 8px 15px;
    background-color: #7879c0;
}

.delete-msg {
    padding-top: 10px;
}

.center-align-profile-info {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
    padding: 40px 20px;
    border-radius: 40px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    overflow: hidden;
    background: linear-gradient(to right, #465da7 50%, transparent 50%);
}

.left-align-profile-info,
.right-align-profile-info {
    flex: 1;
}

.left-align-profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-align-profile-info {
    padding-left: 65px;
}

.circle-info {
    display: flex;
    align-items: center;
}

.circle {
    width: 150px;
    height: 150px;
    background-color: #02244fe6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 78px;
    font-weight: 500;
    margin-right: 10px;
}

.total-records-size {
    padding-top: 30px;
    margin-left: 20px;
}

.Info-display {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.Info-details {
    color: #1e1354;
}

.back-to-main {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: -5px;
    margin-left: 20px;
    cursor: pointer;
}

.back-to-main:hover {
    transition: 0.1s linear;
    transform: scale(1.15);
}

.profile-horizontal-row{
    margin-top: 35px; 
    margin-bottom: 35px;
    border-top: 1px solid #a99c9c;
}

.center-align-profile-info {
    padding: 20px;
    border-radius: 0px;
    flex-direction: column-reverse;
    text-align: left;
    background: none;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    margin-left: 5px;
}

.left-align-profile-info,
.right-align-profile-info {
    padding: 0;
    width: 100%;
}

.logo-redirect {
    position: absolute;
    right: 20px;
}

.left-align-profile-info,
.right-align-profile-info {
    padding: 0px;
    margin: 0px;
}

.left-align-profile-info {
    order: 2;
    margin-top: 35px;
    flex-direction: row;
    color: #fff;
    align-content: center;
}

.circle-info {
    display: flex;
    justify-content: left;
    align-items: left;
}

.circle {
    margin-right: 10px;
}

.total-records-size {
    padding-top: 0;
    text-align: left;
}

.right-align-profile-info {
    padding-left: 0;
    margin-top: 50px;
    margin-left: 20px;
}

.Info-display {
    color: #191e3e;
}
.Info-details {
    color: #4b5063;
}

.profile-Intro-options{
    display: flex;
    flex-direction: row;
}

.profile-section-select-option{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.visible-edit{
    margin-right: 40px;
}

.visible-info{
    margin-right: 40px;
}

@media (max-width: 600px) {

}