@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400&display=swap");

.homepage-component {
    background-color: #e6eaf1;
    font-family: "Roboto Condensed";
}

.HeomePageBodyComponetDiv {
    overflow: hidden;
    position: relative;
    height: 100vh;
    background-color: #1a2c45;
}

.homepagebg,.videoTag{
    position: absolute;
    height: 100vh;
    right: 0;
}

#webgl-canvas {
    background: radial-gradient(circle farthest-corner at center top, #071021, #19324a);
}

.Intro {
    color: #ffffff;
    text-decoration: none;
    letter-spacing: 0.05em;
    display: inline-block;
    position: absolute;
    top: 30%;
    left: 50%;
    text-align: center;
    border-radius: 10px;
    font-family: "Roboto Condensed";
}

.intromsg {
    font-size: 150%;
    padding: 7px 7px;
    margin: 12px 0px;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    width: calc(100vw - 10px);
    font-weight: 400;
}

.intromsg::after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 7%;
    height: 2px;
    background-color: #ff3366;
}

.introalign {
    margin-top: 100px;
    transform: translate(-48%, -50%);
}

.introbutton {
    color: #ffffff;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: #e62958;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #bb133d;
    }
}

.introtxt {
    margin: 20px 0;
    font-family: "Work Sans", sans-serif;
    line-height: 27px;
    font-weight: 300;
    width: calc(100vw - 25px);
    font-size: 20px;
}

.introtxtsmall {
    margin: 10px 0;
    font-family: "Work Sans", sans-serif;
    line-height: 27px;
    font-weight: 500;
    width: calc(100vw - 25px);
    font-size: 13px;
}

.website-information-title {
    text-align: center;
    margin-top: 40px;
    font-weight: 600;
    font-size: 32px;
}

.website-information-subtitle {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 17px;
}

.website-information {
    display: flex;
    flex-direction: row;
    margin: 30px;
    padding: 50px 0;
    justify-content: space-around;
    background: #d1dfe9;
    border-radius: 40px;
}

.homepage-description-audiocomponent {
    padding: 20px 0;
    display: flex;
    align-items: center;
    max-width: 500px;
    font-size: 18px;
    font-weight: 600;
    color: #070707b5;
}

.website-brief {
    display: flex;
    flex-direction: row;
    height: 200px;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff;
}

.echovault-features {
    max-width: 1072px;
    margin: 0 auto;
    text-align: center;
}

.features-list {
    display: flex;
    flex-direction: row;
    margin: 30px;
    padding: 40px 0;
    justify-content: space-around;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 600;
    color: #070707bc;
    position: relative;
}

.feature {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #070707b5;
    margin: 0px 20px;
}

.feature-record-icon:after {
    content: "\e029";
    font-family: "Material Icons";
}

.feature-download-icon:after {
    content: "\f090";
    font-family: "Material Icons";
}

.feature-storage-icon:after {
    content: "\e1db";
    font-family: "Material Icons";
}

.feature-share-icon:after {
    content: "\e80d";
    font-family: "Material Icons";
}

.feature-extension-icon:after {
    content: "\e87b";
    font-family: "Material Icons";
}

.feature-icon {
    font-size: 28px;
    margin-right: 5px;
    color: #314e74;
}

.website-information-ext-demo{
    max-width: 654px;
    padding: 0px;
    margin: 10px 0px;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .website-information {
        display: flex;
        flex-direction: column;
        margin: 10px;
        margin-bottom: 20px;
        padding: 10px 0px 20px 0px;
    }

    .website-information-alternative{
        flex-direction: column-reverse;
    }

    .homepage-description-audiocomponent {
        padding: 15px 15px 10px 15px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 12000px;
        font-size: 18px;
        font-weight: 600;
        color: #070707b5;
    }

    .homepage-information-audiocomponent {
        margin: 0 auto;
    }
}
@media (max-width: 1072px) {
    .features-list {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        width: 300px;
        margin: 0 auto;
    }
}
@media (max-width: 768px) {
    .website-information {
        display: flex;
        flex-direction: column;
        margin: 10px;
        padding: 10px 0px 20px 0px;
    }

    .website-information-alternative{
        display: flex;
        flex-direction: column-reverse;
    }

    .homepage-description-audiocomponent {
        padding: 15px 15px 10px 15px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 900px;
        font-size: 18px;
        font-weight: 600;
        color: #070707b5;
        margin-top: 20px;
    }

    .homepage-information-audiocomponent {
        margin: 0 auto;
        width: 95%;
    }

    .homepage-information-img{
        width: 100%;
    }

    .website-information-ext-demo{
        width: 94%;
        margin-left: 3%;
    }
}
