@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.record-card {
    position: relative;
    border: 1px solid #ccc;
    margin: 15px 15px;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
    border-radius: 20px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    flex: 0 0 23%;
    background-color: #f5f5f5;
    font-family: "Roboto Condensed";
}

.records-canvas {
    cursor: pointer;
}

.record-image {
    position: relative;
    flex: 80%;
    overflow: hidden;
}

.record-image img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropdown {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    background-color: #ffffff;
    border-radius: 15px;
    margin: 33px 13px;
    z-index: 10;
}

.record-card:hover .dropdown {
    display: flex;
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 10px;
    padding: 5px;
}

.dropdown ul li {
    padding: 5px 10px;
    border-radius: 15px;
}

li:hover {
    background-color: #dbe3e8;
    cursor: pointer;
}

.dropdown ul li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.options-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 100;
}

.time-display {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 3px 10px;
    border-radius: 20px;
}

.record-footer {
    flex: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    font-size: larger;
    justify-content: center;
    align-items: center;
    margin: 5px 10px 15px 10px;
}

.footer-player {
    width: 100%;
}

.footer-old {
    font-weight: 500;
    font-size: 12px;
}

.edit-input {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: large;
    justify-content: center;
    align-items: center;
    padding: 0.8px;
}

.edit-input:focus {
    outline: none;
}

.record-card {
    max-height: 400px;
    margin-right: 35px;
    flex-basis: calc(25% - 50px);
}

@media (max-width: 1900px) {
    .record-card {
        max-height: 400px;
        margin-right: 25px;
        flex-basis: calc(33.33% - 40px);
    }
}

@media (max-width: 1450px) {
    .record-card {
        max-height: 400px;
        flex-basis: calc(33.33% - 40px);
    }
}

@media (max-width: 1450px) {
    .record-card {
        max-height: 400px;
        margin-right: 15px;
        flex-basis: calc(50% - 40px);
    }
}

@media (max-width: 1350px) {
    .record-card {
        max-height: 400px;
        flex-basis: calc(50% - 30px);
    }
}

@media (max-width: 750px) {
    .record-card {
        max-height: 400px;
        flex-basis: calc(100% - 80px);
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 600px) {
    .record-card {
        max-height: 400px;
        flex-basis: calc(100% - 50px);
        margin-left: auto;
        margin-right: auto;
    }
}

.visualizer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.track {
    width: 1%;
    height: 25%;
    background: rgba(11, 9, 18, 0.55);
    transition: 0.01s;
}

.track:nth-child(2n) {
    height: 15%;
}

.track:nth-child(3n) {
    height: 20%;
}

.track:nth-child(7n) {
    height: 35%;
}

.audioPlayer {
    --primary: #f5f5f5;
    --secondary: #00132a;
    align-items: center;
    margin: 0 auto;
    font-family: "Roboto Condensed";
}

.progressBarContainer {
    width: 50%;
}

.progressContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.currentTime,
.duration {
    font-family: "Roboto Condensed";
    font-size: 16px;
    width: 15%;
    display: flex;
    justify-content: center;
}

.currentTime {
    margin-right: 10px;
    margin-left: -10px;
}

.duration {
    margin-left: 10px;
    margin-right: -10px;
}

.progressBar {
    --bar-bg: #e0ddea;
    --seek-before-width: 0;
    --seek-before-color: #cec6e7;
    --knobby: #0a2445;
    --selectedKnobby: #124c98;

    appearance: none;
    background: var(--bar-bg);
    border-radius: 10px;
    width: 100%;
    height: 11px;
    outline: none;
}

.progressBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 10px;
    width: 100%;
    height: 11px;
    outline: none;
}

.progressBar::-moz-range-track {
    background: var(--bar-bg);
    border-radius: 10px;
    width: 100%;
    height: 11px;
    outline: none;
}

.progressBar::-moz-focus-outer {
    border: 0;
}

.progressBar::before {
    content: "";
    height: 11px;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.progressBar::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 11px;
}

.progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
}

.progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
}

.progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
}

.progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forwardBackward {
    background: none;
    border: none;
    font-family: "Roboto Condensed";
    font-size: 20px;
    cursor: pointer;
    margin: 0 20px;
    color: var(--secondary);
}

.forwardBackward:hover {
    color: var(--primary);
}

.playPause {
    font-size: 40px;
    background: var(--primary);
    border: none;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 20px;
}

#AudioVisualComponentDiv {
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #191942;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.visualizer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.track {
    width: 1%;
    height: 25%;
    background: rgba(11, 9, 18, 0.55);
    transition: 0.01s;
}

.track:nth-child(2n) {
    height: 15%;
}

.track:nth-child(3n) {
    height: 20%;
}

.track:nth-child(7n) {
    height: 35%;
}
