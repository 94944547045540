@import "https://fonts.googleapis.com/icon?family=Material+Icons|Roboto";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

* {
    outline: none;
}

.RecorderComponentDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-family: "Roboto Condensed";
}

.upper {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 5%;
    flex-direction: column;
}

.record_btn {
    width: 40px;
    height: 40px;
    font-family: "Material Icons";
    font-size: 25px;
    color: #ffffff;
    background: #912418;
    border: 2px solid #912418;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.15s linear;
}

.record_btn:hover {
    transition: 0.15s linear;
    transform: scale(1.05);
}

.record_btn:active {
    background: #3b0a0a;
    border: 2px solid #3b0a0a;
}

.record_btn:after {
    content: "\E029";
}

.record_btn[disabled] {
    border: 2px solid #ccc;
}

.record_btn[disabled]:after {
    content: "\E02B";
    color: #ccc;
}

.record_btn[disabled]:hover {
    transition: 0.15s linear;
    transform: none;
}

.record_btn[disabled]:active {
    background: none;
}

.recording {
    display: flex;
    align-items: center;
}

.stop-recording {
    position: relative;
    width: 22px;
    height: 22px;
    font-family: "Material Icons";
    font-size: 41px;
    color: #8e0d0d;
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.15s linear;
    margin-right: 10px;
}

.stop-recording:after {
    content: "\ef71";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stop-recording:hover {
    transition: 0.15s linear;
    transform: scale(1.08);
}

.stop-recording:active {
    color: #3b0a0a;
}

.stop-recording[disabled] {
    border: 2px solid #ccc;
}

.stop-recording[disabled]:after {
    content: "\E02B";
    color: #ccc;
}

.stop-recording[disabled]:hover {
    transition: 0.15s linear;
    transform: none;
}

.stop-recording[disabled]:active {
    background: none;
}

.recordin-time {
    margin-left: 10px;
    color: #ff1b1b;
    font-size: 16px;
}

.player {
    position: relative;
    font-family: "Material Icons";
    font-size: 41px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.15s linear;
    padding: 28px 20px;
}

.player:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.player:hover {
    transition: 0.15s linear;
    transform: scale(1.08);
}

.player[disabled] {
    border: 2px solid #ccc;
}

.player[disabled]:after {
    content: "\E02B";
    color: #ccc;
}

.player[disabled]:hover {
    transition: 0.15s linear;
    transform: none;
}

.player[disabled]:active {
    background: none;
}

.delete-record {
    margin-right: 80px;
    margin-left: 20px;
    color: #b52834;
}

.delete-record:active {
    color: #5b1218;
}

.delete-record:after {
    content: "\e872";
}

.play-pause-recording {
    color: #efe6e6;
    font-size: 40px;
    margin-top: 8px;
    cursor: pointer;
}

.play-pause-recording:active {
    color: #a49393;
}

.play-pause-recording:hover {
    color: #e1dddd;
}

.playing-animation {
    width: 100%;
}

.save-record {
    margin-left: 80px;
    margin-right: 20px;
    color: #76a95c;
}

.save-record:active {
    color: #2e551b;
}

.save-record:after {
    content: "\e5ca";
}

.save-record[disabled]:after {
    content: "\e5ca";
    color: #495e7056;
    border: none;
}

.playing {
    background-color: #495e7056;
    border-radius: 30px;
}

.audio-bar {
    width: 5px;
    background-color: #ffffff;
    display: inline-block;
    animation-name: bar-animation;
    height: 2px;
}

@keyframes bar-animation {
    0% {
        height: 0;
    }
}

.recorded-audio-name-input {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: large;
    justify-content: center;
    align-items: center;
    padding: 0.8px;
}

.recorded-audio-name-input:focus {
    outline: none;
}

.recorded-audio-name,
.recorded-audio-name-input {
    font-family: "Roboto Condensed";
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    background-color: rgb(59 63 66 / 12%);
    margin-bottom: 10px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.edit-recorded-name {
    margin-left: auto;
    position: absolute;
    right: 10px;
    color: #282a4d;
    background: none;
    border: none;
}

.recorded-audio-name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 30px;
}

.edit-recorded-name:after {
    content: "\e3c9";
    font-family: "Material Icons";
    font-size: 24px;
    margin-left: 5px;
}

.edit-recorded-name:hover {
    transform: scale(1.1);
}

.edit-recorded-name:active {
    color: #5559aa;
}

.recorded-audio-name-input {
    font-family: "Roboto Condensed";
    color: #ccc;
    background-color: rgb(59 63 66 / 12%);
    border: none;
    text-align: center;
    width: 250px;
    font-size: 15px;
    font-weight: 500;
}

.close-recording {
    top: 10px;
    position: absolute;
    right: 10px;
    color: #822828;
    background: none;
    border: none;
}

.close-recording:hover {
    transform: scale(1.2);
}

.close-recording:active {
    color: #720808;
}

.close-recording:after {
    content: "\e5cd";
    font-family: "Material Icons";
    font-size: 24px;
    margin-left: 5px;
}
