@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:600");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:500");

.create-share-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-family: "Roboto Condensed";
}

.share-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdfdfd;
    border-radius: 15px;
    padding: 20px 30px;
    z-index: 200;
    font-family: "Roboto Condensed";
}

.share-email-field {
    padding: 10px;
    border-radius: 20px;
    width: 350px;
    height: 45px;
    font-size: 16px;
}

.share-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.create-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fdfdfd;
    border-radius: 15px;
    padding: 10px 0px;
    margin-bottom: 10px;
    z-index: 200;
    font-family: "Roboto Condensed";
}

.share-email-field {
    padding: 10px;
    border-radius: 20px;
    width: 350px;
    height: 45px;
    font-size: 16px;
    margin-right: 10px;
    border: solid;
    border-width: 1px;
    border-color: #adadae;
}

.create-share-button {
    width: 100px;
    padding: 8px 20px;
    margin-right: 20px;
    border: none;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 600;
    color: aliceblue;
    background-color: #023e8ae6;
}

.create-share-title {
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-left: 6px;
}

.share-people-list {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
}

.create-share-people-list {
    max-height: 155px;
    overflow-y: auto;
    flex-grow: 1;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #707276 #fdfdfd;
}

.create-share-people-list::-webkit-scrollbar {
    width: 4px;
}

.create-share-people-list::-webkit-scrollbar-track {
    background: #fdfdfd;
}

.create-share-people-list::-webkit-scrollbar-thumb {
    background-color: #5e5e5f;
    border-radius: 6px;
}

.create-shared-with-person {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 100%;
    border-radius: 20px;
}

.create-shared-with-person:hover {
    background-color: #eaeff0bc;
}

.record-shared-with-name {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 500;
    color: rgb(74, 73, 73);
}

.create-share-title {
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-left: 6px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.record-delete-button {
    padding: 5px 14px;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: aliceblue;
    background-color: #6c1f1f;
    margin-right: 10px;
}

.record-delete-button:hover {
    background-color: #520606;
}

.close-create-share {
    top: 2px;
    position: absolute;
    right: 7px;
    color: #000000;
    background: none;
    border: none;
}

.close-create-share:hover {
    transform: scale(1.05);
}

.close-create-share:active {
    color: #720808;
}

.close-create-share:after {
    content: "\e5cd";
    font-family: "Material Icons";
    font-size: 24px;
    margin-left: 5px;
}

.tooltip-arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #e9eded;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.email-tooltip {
    position: absolute;
    color: #5b5a5a;
    z-index: 1001;
    margin-top: 80px;
    overflow: hidden;
}

.email-tooltip-email {
    padding: 8px 15px;
    border: 0px solid #ddd;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #e9eded;
}

.last-position {
    margin-top: -90px;
}

.last-position-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9eded;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
}

.last-position-email {
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    .share-email-field {
        width: 200px;
    }

    .share-input-wrapper {
        width: 350px;
    }

    .create-share-button {
        margin-right: 0px;
    }

    .create-share {
        background-color: transparent;
    }
}