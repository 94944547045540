* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #3e3e40 #fdfdfd00;
    max-width: 3000px;
    left: auto;
    right: auto;
}

*::-webkit-scrollbar {
    width: 0;
    overflow: overlay;
}

*::-webkit-scrollbar-track {
    background-color: inherit;
    border-radius: 10px;

}

*::-webkit-scrollbar-thumb {
    background-color: #5e5e5f;
    border-radius: 6px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.app-echovault{
	height: 100%;
}

.sidebar {
	height: 100%;
    width: 300px;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #e6eaf1;
    padding-top: 20px;
    border-top-right-radius: 20px;
    z-index: 1000;
    transition: left 0.3s ease;
}

.content {
    margin-left: 300px;
    padding: 0 3%;
}

.no-sidebar{
    margin-left: 0px;
    padding: 0px;
}

.sidebar-mobile-over{
    margin-left: 0px;
}

.sidebar-close{
    left: -300px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
