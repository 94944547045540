@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400");

.FooterComponent {
    background-color: #1f334e;
    color: #ffffff;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
}

.footer-information {
    flex: 1;
}

.footer-title {
    font-size: 26px;
    font-weight: bold;
}

.footer-message {
    font-size: 16px;
    margin-top: 20px;
    letter-spacing: 0.05em;
}

.footer-copyright {
    font-size: 13px;
    margin-top: 34px;
}

.footer-form {
    flex: 1;
    text-align: center;
    position: relative;
}

.footer-form::before {
    content: "";
    background-color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
}

.feedback-form {
    flex: 1;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    gap: 10px;
}

.feedback-form h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field-label {
    flex: 1; /* Take up available space */
    text-align: center; /* Center-align the field names */
    margin-right: 10px; /* Add spacing between label and input */
}

.form-input {
    flex: 2;
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.form-submit-button {
    background-color: #023E8A;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}

.form-submit-button:hover {
    background-color: #0955b7; /* Button background color on hover */
}

.footer-copyright {
    visibility: visible;
}

.footer-copyright-form-bottom {
    visibility: hidden;
}

.footer-policy-links{
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    cursor: pointer;
}

.footer-policy-links a{
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .FooterComponent {
        display: flex;
        flex-direction: column;
    }

    .footer-form::before {
        content: "";
        background-color: #ffffff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0px;
    }

    .footer-copyright {
        visibility: hidden;
        position: absolute;
    }

    .footer-copyright-form-bottom {
        visibility: visible;
    }

    .form-input {
        width: 210px;
    }
}
