@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.create-folder-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-family: "Roboto Condensed";
}

.create-folder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1a3352;
    border-radius: 8px;
    padding: 10px 20px 25px 20px;
    z-index: 200;
    font-family: "Roboto Condensed";
}

.folder-dropdown {
    color: #243743;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    font-weight: 501;
    position: relative;
    cursor: pointer;
}

.folder-icon{
    font-size: 20px;
    margin-bottom: -1px;
    margin-right: 6px;
}

.folder-icon:after {
    content: "\e2c7";
    font-family: "Material Icons";
    font-size: 48px;
    margin-right: 5px;
    color: #b5dee6; /* White color for the folder icon */
}

.input-container {
    display: flex;
    align-items: center;
    margin: 12px 10px;
}

.folder-name-field {
    margin-left: 10px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    width: 200px;
    font-size: 16px;
    height: 35px;
}

.create-folder-button,
.cancel-folder-button {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 7px 34px;
    background-color: #0286b1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.create-folder-button:hover {
    background-color: #047ea7; /* Darker green on hover */
}

.cancel-folder-button {
    margin-right: 18px;
    background-color: #912121; /* Darker green on hover */
}
.cancel-folder-button:hover {
    background-color: #621818; /* Darker green on hover */
}
