@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap");

.record-track-component {
    font-family: "Roboto Condensed";
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 25px;
}

.record-track-canvas {
    background-color: rgb(6, 25, 42);
    width: 100%;
    padding: 0;
}

.bar-controller {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: rgb(6, 62, 130);
    transform: translateY(-50%);
    pointer-events: none;
}

.hover-position-time-display {
    position: absolute;
    color: rgb(13 61 105);
    font-weight: 600;
    display: none;
}
