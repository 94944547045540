#CortonaComponentDiv {
    top: 0;
    left: 0;
    width: 550px;
    height: 320px;
    background-color: #325581;
    position: relative;
    padding: 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#visualizer {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin: 10% auto;
}

#toggle-play {
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 50px;
    left: 50%;
    fill: rgba(11, 9, 18, 0.55);
    min-height: 25px;
    min-width: 25px;
    width: 2%;
    height: 2%;
}

#toggle-play:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    #CortonaComponentDiv {
        width: 100%;
        height: 270px;
    }

    #visualizer {
        position: absolute;
        height: 30%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin: 10% auto;
    }
}

@media (max-width: 480px) {
    #CortonaComponentDiv {
        position: relative;
        width: 100%;
        height: 200px;
    }

    #visualizer {
        position: absolute;
        height: 70%;
        width: 93%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin: 0 auto;
    }

    #toggle-play {
        bottom: 30px;
    }
}
