@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:100");

.pageNotFoundComponent {
    position: absolute;
    background-color: #08033d;
    color: #fff;
    font-size: 100%;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: 100%;
    font-family: "Roboto Condensed";
}

.pageNotFoundbutton {
    font-weight: 300;
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    border: 1px solid #efefef;
    padding: 0.5em;
    border-radius: 3px;
    float: left;
    margin: 6em 0 0 -70px;
    left: 50%;
    position: relative;
    transition: all 0.3s linear;
    cursor: pointer;
}

.pageNotFoundbutton:hover {
    background-color: #161342;
    color: #fff;
}

.pageNotFoundPara {
    font-size: 2em;
    text-align: center;
    font-weight: 100;
}

.pageNotFoundHeader {
    text-align: center;
    font-size: 15em;
    font-weight: 200;
}
