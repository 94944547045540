@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.HeaderComponetDivRegistration {
    height: 65px;
    border-bottom: 3px;
    background-color: #1f334e;
    font-family: "Roboto Condensed";
    color: #f0ecec;
}

.logo {
    margin-left: 2px;
}

a.logo {
    text-decoration: none;
    color: #f0ecec;
    font-size: 30px;
}

.logoImg {
    margin: 10px;
    width: 39px;
    border-radius: 5px;
}

.auth-header-company-name {
    margin-top: 10px;
}
