.folders {
    display: flex;
    flex-wrap: wrap;
}

.folderContent {
    display: flex;
    align-items: center;
    margin-bottom: -7px;
    max-width: calc(25% - 20px);
}

.folder-icon-empty {
    margin-bottom: -5px;
}

.folder-icon-empty:after {
    content: "\e2c8";
    font-family: "Material Icons";
    font-size: 28px;
    margin-right: 5px;
    color: #00132a;
}

.folderDisplay {
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    overflow: visible;
    border-radius: 20px;
    max-height: 100px;
    background-color: #f5f5f5;
    font-family: "Roboto Condensed";
    flex: 0 0 calc(25% - 20px);
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
}

.dropdown-folder {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    background-color: #ffffff;
    border-radius: 15px;
    margin: 33px 13px;
    z-index: 10;
    overflow: visible;
}

.dropdown-folder {
    display: flex;
}

.dropdown-folder ul {
    list-style: none;
    padding: 0;
    margin: 10px;
    padding: 5px;
}

.dropdown-folder ul li {
    padding: 5px 10px;
    border-radius: 15px;
}

li:hover {
    background-color: #dbe3e8;
    cursor: pointer;
}

.dropdown-folder ul li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.options-icon-folder {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #2f0808;
    cursor: pointer;
    z-index: 100;
}

.folder-name {
    font-weight: bold;
    margin-left: 10px;
    max-width: 100px;
    max-height: 17px;
}

.folderDetails {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.folderDisplay {
    cursor: pointer;
}

.totalFiles,
.totalSize {
    margin-left: 10px;
    font-size: 13px;
    color: #555;
}

.totalFiles {
    margin-left: 42px;
}

.edit-input-folder {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: large;
    padding: 0.8px;
    margin-left: 8px;
}

.edit-input-folder:focus {
    outline: none;
}

.folderContent {
    max-width: 100%;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .folderDisplay {
        flex-basis: calc(33.33% - 20px);
    }
}

@media (max-width: 768px) {
    .folderDisplay {
        flex-basis: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .folderDisplay {
        flex-basis: calc(100% - 20px);
    }
}
