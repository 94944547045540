@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");
.LoggedInHeaderComponent {
    color: #fff;
    padding: 10px 0;
    font-family: "Roboto Condensed";
    margin-top: 20px;
}

.header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
}

.header-search-bar {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    max-width: 710px;
}

.header-record-buttons{
    display: flex;
    align-items: center;
    margin-right: auto;
}

.search-bar {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 30px;
    overflow: hidden;
    padding-left: 10px;
    margin-right: 10px;
    max-width: 700px;
}

.search-field[type="text"] {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 16px;
    outline: none;
}

.search-icon {
    color: #000;
    margin-right: 10px;
    cursor: pointer;
}

.buttons-logo {
    display: flex;
    align-items: center;
}

.new-folder-button,
.new-record-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    margin-right: 10px;
}

.new-record-button {
    border-color: #023271e6;
    background-color: #023271e6;
    color: white;
}

.new-folder-button:hover {
    background-color: #eee;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.new-record-button:hover {
    background-color: #02234fe6;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.logo-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
}

.logo-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #09497c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.logo-circle:hover {
    background-color: #114067;
}

.header-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-top: 2px solid transparent;
    padding-top: 10px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.header-category {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 17px;
}

.category-link-container {
    display: inline-block;
    position: relative;
}

.category-link {
    text-decoration: none;
    color: #818383;
    transition: border-bottom 0.3s;
    position: relative;
}

.catagory-selected{
    color: #000;
}

.category-link-container:hover .category-link,
.category-link-container.selected .category-link {
    position: relative;
}

.category-link-container:hover .category-link {
    color: #545656;
}

.category-link-container.selected .category-link:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -23px;
    width: 100%;
    height: 3px;
    background-color: #375883;
    border-radius: 4px;
}

.category-info {
    font-size: 12px;
    color: #ccc;
}

.dropdown-user-info {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    background-color: #f6f6f6;
    border-radius: 15px;
    z-index: 100;
    color: #000000;
    overflow: visible;
    margin: 58px 30px 0px 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-user-info {
    display: flex;
}

.dropdown-user-info ul {
    list-style: none;
    padding: 0;
    margin: 10px;
    padding: 5px;
}

.dropdown-user-info ul li {
    padding: 5px 10px;
    border-radius: 15px;
}

.dropdown-user-info ul li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.subscribe-list-item {
    color: #b64040;
}

.header-horizontal-row{
     border-top: 1.5px solid #dededea8;
     margin-left: 20px;
     width: 99%;
}

.sidebar-toggle-button {
    display: inline-block;
    background: none;
    border: none;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    color: #002756;
    transition: opacity 0.3s, transform 0.3s;
}

@media (max-width: 768px) {
    .mobile-sidebar {
        position: fixed;
        top: 0;
        left: -250px;
        width: 230px;
        height: 100%;
        background-color: #f6f6f6;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 999;
        padding: 10px;
        box-sizing: border-box;
        transition: left 0.3s;
        overflow-y: auto;
        overflow-x: hidden;
        transition: left 0.3s;
    }

    .mobile-sidebar.show {
        left: 0;
    }

    .mobile-sidebar button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
    }

    .sidebar-buttons {
        margin-top: 20px;
    }

    .horizontal-row {
        border: 0;
        border-top: 2px solid #a4a1a1;
        bottom: 20px 0;
    }

    .copyright-notice {
        color: #888;
        font-size: 12px;
        margin-top: 10px;
        margin-left: 28px;
    }
    .folder-icon,
    .record-icon {
        font-size: 20px;
        margin-bottom: 5px;
        margin-right: 6px;
    }

    .header-category {
        display: flex;
        flex-direction: column;
        margin-right: 0px;
        padding: 0 20px;
    }
}

@media (max-width: 600px) {
    .header-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .new-folder-button,
    .new-record-button {
        margin-top: 20px;
    }

    .new-folder-button{
        margin-left: 42px;
    }

    .header-search-bar{
        width: 100%;
    }

    .header-record-buttons{
        width: 100%;
    }
}

@media (max-width: 400px) {
    .header-category {
        display: flex;
        flex-direction: column;
        margin-right: -5px;
        padding: 0 20px;
    }
}