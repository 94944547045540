@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700");

.term-of-services-componenet, .privacy-policy-componenet{
    font-family: "Work Sans", sans-serif;
    font-size: 17px;
    max-width: 1200px;
    margin: 60px auto;
}

.policy-title{
    font-weight: 900;
    font-size: 36px;
}

.policy-subsection-heading, .policy-subsection-information{
    margin-top: 30px;
}

@media (max-width: 1200px) {
    .term-of-services-componenet, .privacy-policy-componenet{
        margin: 60px 30px;
    }
}