.loading-container-used {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -43px;
}

.loading-bars {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    transition: ease 0.2s;
    position: relative;
    background: #fff;
}

.loading-bars li {
    list-style: none;
    height: 30px;
    width: 4px;
    border-radius: 12px;
    background: #2559ba;
    margin: 0 4px;
    padding: 0;
    animation-name: wave1;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: ease 0.2s;
}

.loading-bars li:nth-child(2) {
    animation-name: wave2;
    animation-delay: 0.2s;
}

.loading-bars li:nth-child(3) {
    animation-name: wave3;
    animation-delay: 0.23s;
    animation-duration: 0.4s;
}

.loading-bars li:nth-child(4) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
}

.loading-bars li:nth-child(5) {
    animation-delay: 0.5s;
}

.loading-bars li:nth-child(6) {
    animation-name: wave2;
    animation-duration: 0.5s;
}

.loading-bars li:nth-child(8) {
    animation-name: wave4;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
}

.loading-bars li:nth-child(9) {
    animation-name: wave3;
    animation-delay: 0.15s;
}

@keyframes wave1 {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0.5);
    }
}

@keyframes wave2 {
    from {
        transform: scaleY(0.3);
    }

    to {
        transform: scaleY(0.6);
    }
}

@keyframes wave3 {
    from {
        transform: scaleY(0.6);
    }

    to {
        transform: scaleY(0.8);
    }
}

@keyframes wave4 {
    from {
        transform: scaleY(0.2);
    }

    to {
        transform: scaleY(0.5);
    }
}
